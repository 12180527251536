<template>
  <SelectGroup
    :id="id"
    :label="label"
    :placeholder="placeholder"
    @handlerSelect="handleChangeStage"
    :isValid="isValid"
    :error="error"
    :options="getStages"
    :selectedOption="value"
  />
</template>

<script>
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import { getSelectDefault, getStageOptions } from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: String,
    error: {
      type: String,
      default: 'No puede estar vacio',
    },
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    handleChangeStage(value) {
      this.$emit('handleChangeStage', value);
    },
  },
  computed: {
    getStages() {
      const stageOptions = getStageOptions();
      const stagesTranslated = stageOptions.map((stage) => {
        const newStage = {
          value: stage.value,
          text: this.$i18n.t(stage.text),
        };

        return newStage;
      });

      stagesTranslated.unshift(this.getSelectDefaultText);
      return stagesTranslated;
    },
    getSelectDefaultText() {
      return getSelectDefault('stage');
    },
  },
  components: {
    SelectGroup,
  },
};
</script>
