<template>
  <div class="filters">
    <p class="text__psb">Filtrar</p>
    <div class="sp__v__2x" />
    <DatepickerGropup
      id="createdAtRequestFilter"
      :label="$i18n.t('lb_creation_date')"
      :placeholder="$i18n.t('lb_select_date')"
      @handleChangeDate="handleChangeCreatedAt"
      :value="getCreatedAt"
    />

    <div class="sp__v__2x" />

    <CountrySelector
      id="originRequestFilter"
      :label="$i18n.t('lb_origin')"
      @handleChangeCountry="handleChangeOrigin"
      :value="getOrigin"
    />

    <div class="sp__v__2x" />

    <Specialty
      id="specialtyResquestFilter"
      @handleChangeSpecialty="handleChangeSpecialty"
      :value="getSpecialty"
    />

    <div v-if="!(isClinic || isDoctor)" class="sp__v__2x" />

    <ClinicSelector
      v-if="!(isClinic || isDoctor)"
      id="clinicRequestFilter"
      :label="$i18n.t('lb_clinic')"
      @handleChangeClinic="handleChangeClinic"
      :value="getClinic"
    />

    <div class="sp__v__2x" />

    <StageSelector
      id="stageRequestFilter"
      :label="$i18n.t('lb_stage')"
      @handleChangeStage="handleChangeStage"
      :value="getStage"
    />

    <div class="sp__v__2x" />

    <StatusSelector
      id="statusRequestFilter"
      :label="$i18n.t('lb_status')"
      @handleChangeStatus="handleChangeStatus"
      :value="getStatus"
    />

  </div>
</template>

<script>
import DatepickerGropup from '@/components/atoms/Datepicker/DatepickerGropup.vue';
import CountrySelector from '@/components/atoms/Select/CountrySelector.vue';
import Specialty from '@/components/atoms/Select/Specialty.vue';
import ClinicSelector from '@/components/atoms/Select/ClinicSelector.vue';
import StageSelector from '@/components/atoms/Select/StageSelector.vue';
import StatusSelector from '@/components/atoms/Select/StatusSelector.vue';

export default {
  props: {
    filters: {
      type: Object,
      default: () => ({
        filters: {
          createdAt: null,
          origin: null,
          specialty: null,
          stage: null,
          status: null,
        },
      }),
    },
  },
  data() {
    return {
      createdAt: this.filters.createdAt,
      origin: this.filters.origin,
      specialty: this.filters.specialty,
      clinic: this.filters.clinic,
      stage: this.filters.stage,
      status: this.filters.status,
    };
  },
  methods: {
    handleChangeCreatedAt(value) {
      this.$emit('handleChangeCreatedAt', value);
    },

    handleChangeOrigin(value) {
      const originID = value.id;
      this.$emit('handleChangeOrigin', originID);
    },

    handleChangeSpecialty(value) {
      const specialtyID = value.id;
      this.$emit('handleChangeSpecialty', specialtyID);
    },

    handleChangeClinic(value) {
      this.$emit('handleChangeClinic', value);
    },

    handleChangeStage(value) {
      this.$emit('handleChangeStage', value);
    },

    handleChangeStatus(value) {
      this.$emit('handleChangeStatus', value);
    },
  },
  computed: {
    getCreatedAt() {
      return this.filters.createdAt;
    },

    getOrigin() {
      return this.filters.origin;
    },

    getSpecialty() {
      return this.filters.specialty;
    },

    getClinic() {
      return this.filters.clinic;
    },

    getStage() {
      return this.filters.stage;
    },

    getStatus() {
      return this.filters.status;
    },

    isClinic() {
      return this.$store.getters['user/isClinic'];
    },

    isDoctor() {
      return this.$store.getters['user/isDoctor'];
    },

    isVidasPrime() {
      return this.$store.getters['user/isVidasPrime'];
    },
  },
  components: {
    DatepickerGropup,
    CountrySelector,
    Specialty,
    ClinicSelector,
    StageSelector,
    StatusSelector,
  },
};
</script>
