<template>
  <SelectGroup
    :id="id"
    :label="label"
    :placeholder="placeholder"
    @handlerSelect="handleChangeStatus"
    :isValid="isValid"
    :error="error"
    :options="getStatus"
    :selectedOption="value"
  />
</template>

<script>
import SelectGroup from '@/components/atoms/Select/SelectGroup.vue';
import {
  getSelectDefault,
  getStatusOptions,
} from '@/helpers/ComponentHelper';

export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: '',
    },
    label: String,
    error: {
      type: String,
      default: 'No puede estar vacio',
    },
    isValid: {
      type: Boolean,
      default: null,
    },
  },
  methods: {
    handleChangeStatus(value) {
      this.$emit('handleChangeStatus', value);
    },
  },
  computed: {
    getStatus() {
      const stageOptions = getStatusOptions();
      const statusTranslated = stageOptions.map((stage) => {
        const newStatus = {
          value: stage.value,
          text: this.$i18n.t(stage.text),
        };

        return newStatus;
      });

      statusTranslated.unshift(this.getSelectDefaultText);
      return statusTranslated;
    },
    getSelectDefaultText() {
      return getSelectDefault('status');
    },
  },
  components: {
    SelectGroup,
  },
};
</script>
